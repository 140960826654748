<template>
  <div>
    <MainBanner
      Title="Sıkça Sorulan Sorular"
      Desc=""
      Image="static/img/contactbg.png"
      TextPosition="bottom"
      Height="full"
    />
    <div class="main-continer mt-5">
      <div class="container d-flex flex-column flex-md-row" v-if="this.pageContent">
        <div class="col-md-3 px-0 px-md-3" v-html="this.pageContent.page[0].content"></div>
        <div class="col-md-9 pl-0 pl-md-5 mt-5 mt-md-0">
          <div class="accordion__wrapper"
            v-for="(quest, index) in questionList"
            :key="index">
            <div class="accordion__head d-flex align-items-center justify-content-between">
              <h4 class="">{{ quest.question }}</h4>
              <span class="icons-arrow-down svg-icon"></span>
            </div>
            <div class="accordion__body">
              <p class="font-regular font-white heading-xsmedium">
                {{ quest.answer }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterLanding />
  </div>
</template>
<script>
import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";

export default {
  name: "FAQ",
  components: { MainBanner, ButtonRounded, FooterLanding },
  data() {
    return {
      disabled: false,
      isLoading: false,
      fullPage: false,
      questionList: {},
      pageContent: '',
    };
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    this.runAccordions();
    this.getSSS();
    this.getPageContent('sikca-sorulan-sorular');
    
  },
  methods: {
    getPageContent(page){
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
        console.log(this.pageContent, "post");
      })
    },
    runAccordions() {
      $(document).on("click", ".accordion__head", function() {
        var $not = $(this).parent();
        $(".active").not(this).removeClass("active").next().slideUp(300);
        //  $(".accordion__wrapper:not(:has(.active))").css("background-color", "yellow");
        $(this).toggleClass("active").next().slideToggle(300);
      })
    },
    getSSS() {
      this.$api.getSSS().then((response) => {
        this.questionList = response;
       
      });
    },
  },
};
</script>
<style lang="scss">
.main-continer {
  padding-bottom: 100px;
}
.accordion__wrapper {
  border-top: 2px solid rgba(10, 38, 57, 0.1);

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 2rem 0px;
  }
  p {
    font-size: 1rem;
    padding-bottom: 4rem;
  }
  span {
    width: 2rem;
    height: 2rem;
    background: var(--purpleDark);
    transition: 0.3s ease;
  }
  .accordion__head {
    cursor: pointer;
  }
  .active {
    span {
      transform: rotate(-180deg);
    }
  }
  .accordion__body {
    display: none;
  }
}
</style>